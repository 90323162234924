import PropTypes from "prop-types"
import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

const Footer = ({ siteTitle }) => (
  <footer
    style={{
      background: `#222`,
      margin: `0 auto`,
      maxWidth: "100%",
      paddingTop: 0,
      paddingBottom: "150px",
      color: "#fffD",
    }}
  >
    <div
      style={{
        margin: "0 auto",
        maxWidth: "1024px",
      }}
      className="flex flex-col sm:flex-row"
    >
      <article
        style={{
          padding: "2em 1em",
          display: "flex",
          flexDirection: "column",
        }}
        className="flex justify-start is-one-third"
      >
        <h3>Sociala medier</h3>
        <a href="https://www.facebook.com/albinstrafik/" className="fbLink">
          <img src={require("./../images/FB.png")} alt="facebook" width="42" />
        </a>

        <h4>
          © {new Date().getFullYear()}, {siteTitle}
        </h4>
      </article>
      <article
        style={{
          padding: "2em 1em",
          display: "flex",
          flexDirection: "column",
        }}
        className="columns column is-one-third"
      >
        <h3>Amins Trafikskola </h3>
        <p>
          Vårväderstorget 4 <br />
          418 31 Göteborg
          <br />
          <br />
          <FontAwesomeIcon icon={faPhone} /> : 031 54 42 00
          <br />
          <FontAwesomeIcon icon={faEnvelope} /> : info@aminstrafikskola.se
          <br />
        </p>
      </article>
      <article
        style={{
          padding: "2em 1em",
          display: "flex",
          flexDirection: "column",
        }}
        className="columns column is-one-third"
      >
        <h3>Öppettider</h3>
        <p>
          <b>Mån - Tors</b> 10:00 - 17:00
        </p>
        <span>Org. Nr: 559181-2176</span>
        <a href="/conditions" className="underline">
          Köpvillkor
        </a>
      </article>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
