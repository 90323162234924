import React from "react"
import Layout from "../layout"
import { Link } from "gatsby"

const Conditions = () => {
  return (
    <Layout>
      <div
        style={{
          padding: "3em",
          backgroundColor: "#FAFAFA",
          color: "333",
          maxWidth: "50em",
          textAlign: "left",
        }}
      >
        <h1>Köpvillkor</h1>
        <p>
          Följande villkor gäller för Amins Trafikskola AB:s försäljning i
          Sverige över Internet till konsument.
        </p>
        <h2 className="font-bold text-lg">Beställning mm</h2>
        <p>
          Beställning av en vara eller tjänst sker på vår hemsida. När du
          beställt och betalat skickar vi en orderbekräftelse till dig. För att
          handla måste du vara över 18 år. Är du ännu inte 18 år måste du ha
          målsmans tillstånd för att få handla.
        </p>
        <h2 className="font-bold text-lg">Priser</h2>
        <p>
          Priserna anges i svenska kronor och inkluderar moms om inget annat
          anges. Priset på varan/tjänsten är det som anges på webbplatsen på
          beställningsdagen.
        </p>
        <h2 className="font-bold text-lg">Betalning</h2>
        <p>
          Betalning görs i samband med beställningen här kan du även fylla på
          ditt elevsaldo eller reglera ett minussaldo och med kontokort eller
          Swish, vilket innebär att du betalar din order direkt via kontokort
          (Visa eller Mastercard) eller Swish. Vi samarbetar med Klarna payment.
        </p>
        <h2 className="font-bold text-lg">Leverans och fraktkostnad</h2>
        <p>
          Du får dina varor inom 2-4 arbetsdagar efter det att vi fått din
          beställning.
          <br />
          Vi svarar inte för dröjsmål som beror på hinder utanför vår kontroll
          (force majeure). <br />
          Vi levererar endast inom Sverige.
          <br />
          För varor som inte levereras över Internet tillämpar vi enhetsfrakt på
          65 kr per order.
          <br />
          Vid avhämtning av varor utgår ingen fraktkostnad.
        </p>
        <h2 className="font-bold text-lg">Paket som inte hämtas ut</h2>
        <p>
          Hämtar du inte ut ditt paket alls, så skickas det tillbaka till oss.
          Vi tar då ut en avgift på 150 kr för att täcka de kostnader vi har i
          samband med det outlösta paketet. Om du inte meddelar oss annat inom
          14 dagar från det att varan återkommit till oss upphör annulleras
          köpet. Du kommer då att återfå vad du betalat, med avdrag för
          nyssnämnda kostnader. Återbetalning sker med samma betalningsmetod som
          vid köpet, dvs. genom insättning på det kort som du använde vid köpet.
        </p>
        <h2 className="font-bold text-lg">Ångerrätt/retur</h2>
        <p>
          Du har rätt att frånträda detta avtal utan att ange något skäl inom 14
          dagar, från det att du, eller någon annan som du angett, mottagit
          varan eller, om du beställt en tjänst, från det att avtalet ingicks.
          Gäller avtalet flera varor som ska levereras var för sig, räknas
          ångerfristen från leverans av den sista varan. Gäller avtalet däremot
          återkommande leverans av varor under en viss tid, räknas ångerfristen
          från den dag då den första varan levereras. För att utnyttja din
          ångerrätt ska du innan ångerfristen löpt ut skicka ett klart och
          tydligt meddelande om ditt beslut att frånträda avtalet. Meddelandet
          kan skickas per post, fax eller e-post till den adress som anges i
          början av dessa köpvillkor. Gör du inte detta har du inte längre rätt
          att ångra ditt köp. På Konsumentverkets hemsida,
          www.konsumentverket.se, finns ett standardformulär som kan användas
          vid utövande av ångerrätten. Din rätt att ångra köpet gäller inte
          avtal om inkvartering eller biluthyrning som ska tillhandahållas en
          bestämd dag eller under en bestämd tidsperiod. Om ångerrätten utövas
          efter det att tjänsten med ditt samtycke har påbörjats har
          trafikskolan rätt till ersättning med en proportionell andel av det
          avtalade priset för den del av tjänsten som utförts. Om du utövat
          ångerrätten ska du utan dröjsmål och senast 14 dagar efter den dag då
          du meddelade oss om ditt beslut att frånträda avtalet återsända köpt
          vara. Varan/varorna ska returneras till Amins trafikskola AB,
          Vårväderstorget 5, 41831 Göteborg. <br />
          <br />
          Vid retur/ånger köp krävs följesedel, kvitto eller ordernummer. Retur
          får inte ske mot postförskott. Du ska betala kostnaden för
          återsändandet av varan. Vid transport av varan från dig till oss står
          du risken för varan. Detta innebär att om varan kommer bort eller
          skadas under transporten till oss ansvarar du för detta. Du är också
          ansvarig för varornas minskade värde till följd av annan hantering än
          vad som är nödvändig för att fastställa varornas art, egenskaper och
          funktion. Om du frånträder avtalet kommer alla betalningar du gjort
          att återbetalas, med undantag för extra leveranskostnader på grund av
          att du valt ett annat leveranssätt än den billigaste standardleverans
          vi erbjuder. Återbetalning sker med samma betalningsmetod som vid
          köpet. Detta innebär att vi sätter tillbaka köpesumman, med eventuella
          avdrag enligt ovan, på samma kort som du använde vid köpet.
          Återbetalningen sker inom 14 dagar från det att vi mottagit din retur
          eller du på annat sätt visat att varan sänts tillbaka. Gäller avtalet
          köp av tjänst sker återbetalning senast inom 14 dagar från den dag då
          vi tog emot ditt meddelande om att avtalet frånträtts.
        </p>
        <h2 className="font-bold text-lg">Avbeställning</h2>
        <p>
          Förutsatt att beställning av vara inte skickats iväg har du möjlighet
          att utan kostnad avbeställa hela eller viss del av beställning av
          varor genom att kontakta oss per telefon. Gäller avtalet
          utbildningstjänst tillämpas STR:s praxis angående ersättning och
          återbetalning vid avbeställning m.m. Du finner denna praxis på{" "}
          <a href="www.str.se">www.str.se</a>
        </p>
        <h2 className="font-bold text-lg">Behandling av personuppgifter</h2>
        <p>
          I samband med att du beställer en vara eller tjänst på vår hemsida
          godkänner du att Amins Trafikskola AB registrerar och elektroniskt
          lagrar de personuppgifter som du lämnar i samband med beställningen.
          Uppgifterna kommer att användas för administration av din beställning.
        </p>
        <h2 className="font-bold text-lg">Reklamation</h2>
        <p>
          Om en vara är felaktig, felexpedierad eller transportskadad ska du så
          snart som möjligt kontakta oss. Vid övriga reklamationer eller
          klagomål ska du vända dig till ansvarig på trafikskolan. Om du inte
          blir nöjd med trafikskolans hantering har du som elev vid en STR
          ansluten trafikskola möjlighet att pröva ärendet i STR:s
          reklamationsnämnd, se www.str.se/reklamation. Enligt konsumentköplagen
          (1990:932) och konsumenttjänstlagen (1985:716) gäller att reklamation
          som huvudregel aldrig kan ske senare än tre år från det att varan har
          mottagits eller tjänsten har avslutats.
        </p>
        <h2 className="font-bold text-lg">Avbokningsregler:</h2>
        <ul className="flex flex-col place-items-start gap-2 text-left list-disc p-0 m-0 pb-8">
          <li>
            Avbokning av våra kurser ska ske senast 3 dagar innan kursstart
            annars debiteras du full pris.
          </li>
          <li>
            Avbokning av intensivkurser Bil, MC och Moped ska ske senast två
            veckor innan kursstart för återbetalning av kursavgiften där endast
            administrationsavgiften med 10% dras av. Du får behålla bokpaketet
            och onlinetester.
          </li>
        </ul>

        <p>
          Vid avbokning en vecka innan kursstart får du tillbaka 50 % av
          kursavgiften exklusive administrationsavgiften. Du får behålla
          bokpaketet och onlinetester.
        </p>
        <ul className="flex flex-col place-items-start gap-2 text-left list-disc p-0 m-0 pb-8">
          <li>
            Vid avbokning samma dag som kursstart så debiteras du fullpris om ej
            godtagbar anledning.
          </li>
          <li>
            Har du en körlektion eller kurs och du är sjuk så att du kan inte
            delta så behöver du meddela oss via mejl eller sms till +46723881132
            senaste kl 07:00 på morgonen och för att inte bli debiterad så
            kräver det ett läkarintyg från vårdcentralen som du behöver lämna
            till oss. (Vi accepterar inte online-läkare)
          </li>
        </ul>

        <h2 className="font-bold text-lg">Giltighetstid</h2>
        <p>
          Köpta paket ska utnyttjas inom avsatt giltighetstid vilket är
          följande:
        </p>
        <ul className="flex flex-col place-items-start gap-2 text-left list-disc p-0 m-0 pb-8">
          <li>MC: 1 år.</li>
          <li>Intensivkurs: 3 månader.</li>
          <li>Övriga paket: 6 månader.</li>
        </ul>
        <h2 className="font-bold text-lg">Lagval och forum</h2>
        <p>
          Svensk rätt ska tillämpas på samtliga köp enligt dessa avtalsvillkor.
          Skulle det uppstå en tvist mellan parterna som inte kan lösas på
          plats, kan tvisten prövas i domstol (tingsrätt). Kunden (eleven) kan
          även vända sig till Allmänna reklamationsnämnden (ARN) eller STR:s
          reklamationsnämnd (gäller endast kunder på en trafikskola ansluten
          till STR).
        </p>
        <h2 className="font-bold text-lg">Kontaktuppgifter</h2>
        <p>
          Amins Trafikskola AB
          <br /> Engelbrektsgatan 5, 41831 Göteborg <br />
          Org. Nr: 559181-2176
          <br /> Tele: 031-544200 <br />
          Hemsida: www.aminstrafikskola.se <br />
          e-post: info@aminstrafikskola.se
        </p>
      </div>
    </Layout>
  )
}

export default Conditions
